import React, { Component, Fragment } from "react";
import {
  ArrayInput,
  TextInput,
  FormDataConsumer,
  SimpleFormIterator,
  SelectInput,
  FileInput,
  BooleanInput,
  DateTimeInput
} from "react-admin";

import TranslatableParagraph from "../TranslationComponents/TranslatableParagraph";

import { useForm } from "react-final-form";

import ImageInput from "../ImageInput";

import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import RichTextInput from "ra-input-rich-text";

import CustomFormIterator from "../CustomFormIterator";

import CustomImageField from "../CustomImageField";
import CustomVideoField from "../CustomVideoField";

import { BannerToolbarOptions } from "../../constants";

import ButtonToggle from "../ButtonToggle";

import RoundButtonToggle from "../RoundButtonToggle";

import { bannerTextStylesChoices, bannerTypesChoices } from "../../constants/bannerStyles";

import SimpleSlider from "./SimpleSlider";
import { ValidateBannerLink } from "../../resources/pages/validators";

const BannerRoundButtonToggle = ({ bannerIndex, bannerInputState }) => {
  const form = useForm();
  const revertBanner = index => {
    const { savedBanner = {} } = bannerInputState;

    form.change(
      "bannersCarousel[" + index + "].banner_type_class_name",
      savedBanner.banner_type_class_name
    );
    form.change("bannersCarousel[" + index + "].banner_title", savedBanner.banner_title);
    form.change("bannersCarousel[" + index + "].banner_link", savedBanner.banner_link);
    form.change("bannersCarousel[" + index + "].imageRawMobile", savedBanner.imageRawMobile);
    form.change("bannersCarousel[" + index + "].imageRawDesktop", savedBanner.imageRawDesktop);
    form.change("bannersCarousel[" + index + "].imageRaw4K", savedBanner.imageRaw4K);
    form.change("bannersCarousel[" + index + "].show", false);
    form.change("bannersCarousel[" + index + "].videoRaw", savedBanner.videoRaw);
  };

  return (
    <RoundButtonToggle
      source={"bannersCarousel[" + bannerIndex + "].cancel"}
      onChange={e => {
        revertBanner(bannerIndex);
      }}
    />
  );
};

export default class BannerInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nextId: 0,
      ids: [],
      fields: {},
      slider: null,
      savedBanner: {},
      deleteOnRevert: false,
      videoBanner: false,
      addNewItem: false
    };
  }

  setFields = fields => {
    if (fields && fields.value && fields.value) {
      const element = fields.value[fields.value.length - 1];
      const index = fields.value.indexOf(element);
      const { savedBanner } = this.state;
      if (
        element &&
        element.cancel &&
        Object.keys(savedBanner).length === 0 &&
        savedBanner.constructor === Object
      ) {
        fields.remove(index);
      }
    }
    this.setState({
      fields: fields,
      nextId: fields ? fields.length : 0,
      ids: fields && fields.length ? fields.map((record, index) => index) : []
    });
  };

  setSlider = slider => {
    this.setState({
      slider
    });
  };

  saveBanner = banner => {
    this.setState({ savedBanner: banner });
  };

  removeField = (index, fields) => {
    this.setState(state => {
      const newIds = [...state.ids];
      newIds.splice(index, 1);

      return {
        ids: newIds
      };
    });
    fields.remove(index);
  };

  addField = (fields, addNewItem = false) => {
    this.setState({ savedBanner: {}, deleteOnRevert: true, addNewItem }, () => {
      if (fields && typeof fields.push == "function") {
        this.setState(state => {
          const newIds = [...state.ids];
          newIds.push(this.nextId++);

          return {
            ids: newIds
          };
        });

        if (fields.length) {
          this.state.slider.slickGoTo(fields.length);
        }

        fields.push({ show: true });
      }
    });
  };

  render() {
    const { addNewItem } = this.state;

    const ValidateCarouselLink = () => {
      return (
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            let incorrectBanners = [];
            formData.bannersCarousel.forEach(bannerCarousel => {
              if (bannerCarousel?.banner_link) {
                if (
                  bannerCarousel?.banner_link?.startsWith("/categories/") ||
                  bannerCarousel?.banner_link?.startsWith("https://")
                ) {
                  return null;
                } else {
                  incorrectBanners.push(bannerCarousel?.banner_link);
                }
              }
            });

            let revisedIncorrectBanners = incorrectBanners.map(incorrectBanner => {
              return (
                <span
                  style={{
                    backgroundColor: "rgba(244, 67, 54, 0.12)",
                    color: "#f44336",
                    margin: "0px 5px",
                    padding: "5px",
                    borderRadius: "7.5px"
                  }}
                >
                  {incorrectBanner}
                </span>
              );
            });

            return revisedIncorrectBanners?.length ? (
              <p>
                Banner links which may lead to 404 are: <span>{revisedIncorrectBanners}</span>
              </p>
            ) : null;
          }}
        </FormDataConsumer>
      );
    };

    return (
      <Fragment>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <div className="slider-container">
              <div
                style={{
                  width: "100%",
                  justifyContent: "flex-end",
                  display: "flex"
                }}
              >
                <Button
                  style={{
                    backgroundColor: "rgb(52, 152, 219)",
                    color: "rgb(255, 255, 255)"
                  }}
                  onClick={() => {
                    this.setState({
                      savedBanner: {}
                    });
                    this.addField(this.state.fields, true);
                  }}
                >
                  <TranslatableParagraph translationLabel="resources.pageContents.fields.add_new_banner" />
                </Button>
              </div>
              <SimpleSlider
                bannersCarousel={formData && formData.bannersCarousel}
                removeField={this.removeField}
                fields={this.state.fields}
                setSlider={this.setSlider}
                saveBanner={this.saveBanner}
                savedBanner={this.state.savedBanner}
              />
              <ArrayInput
                source="bannersCarousel"
                style={{ marginTop: 40 }}
                label="resources.pageContents.fields.banner_order"
              >
                <SimpleFormIterator
                  disableAdd
                  disableRemove
                  TransitionProps={{ enter: false, exit: false }}
                >
                  <TextInput
                    source="banner_link"
                    disabled
                    style={{
                      width: 500,
                      maxWidth: "100%"
                    }}
                    label="resources.pageContents.fields.link"
                    variant="standard"
                  />
                </SimpleFormIterator>
              </ArrayInput>
              <ValidateCarouselLink />
            </div>
          )}
        </FormDataConsumer>
        <ArrayInput source="bannersCarousel" label=" ">
          <CustomFormIterator setFields={this.setFields} ids={this.state.ids}>
            {arrayfieldProps => {
              const { fields, index } = arrayfieldProps;
              const banner = fields.value[index];

              if (!banner.show) return null;

              if (!banner.banner_type) {
                banner.banner_type = "image";
              }

              return (
                <Dialog maxWidth="md" fullWidth open={banner.show}>
                  <div
                    style={{
                      position: "sticky",
                      right: "0",
                      top: "0",
                      zIndex: 99999
                    }}
                  >
                    <BannerRoundButtonToggle bannerIndex={index} bannerInputState={this.state} />
                  </div>
                  <div style={{ padding: "15px" }}>
                    <Grid container spacing={3}>
                      {/* 
                          Do not show select input if the edit mode is on;
                          Disable select input if the user is adding a new item
                          and the video banner item already exists; 
                      */}
                      {addNewItem && (
                        <Grid item xs={12}>
                          <div>
                            <SelectInput
                              label="resources.pageContents.fields.banner_type"
                              source={"bannersCarousel[" + index + "].banner_type"}
                              choices={bannerTypesChoices}
                              defaultValue="image"
                              disabled={fields.value.some(item => item.banner_type === "video")}
                              variant="standard"
                            />
                          </div>
                        </Grid>
                      )}

                      {banner.banner_type === "video" && (
                        <Grid item xs={12}>
                          <div>
                            <BooleanInput
                              label="resources.pageContents.fields.enable_mobile_autoplay"
                              source={"bannersCarousel[" + index + "].banner_video_mobile_autoplay"}
                            />
                          </div>
                        </Grid>
                      )}

                      {banner.banner_type === "image" && (
                        <Grid item xs={12}>
                          <div>
                            <SelectInput
                              label="resources.pageContents.fields.banner_text_style"
                              source={"bannersCarousel[" + index + "].banner_type_class_name"}
                              choices={bannerTextStylesChoices}
                              variant="standard"
                            />
                          </div>
                        </Grid>
                      )}

                      {banner.banner_type === "image" && (
                        <Grid item xs={12}>
                          <DateTimeInput
                            source={"bannersCarousel[" + index + "].banner_start_at"}
                            label="resources.pageContents.fields.banner_start_date"
                            variant="standard"
                          />
                        </Grid>
                      )}

                      {banner.banner_type === "image" && (
                        <Grid item xs={12}>
                          <DateTimeInput
                            source={"bannersCarousel[" + index + "].banner_end_at"}
                            label="resources.pageContents.fields.banner_end_date"
                            options={{
                              format: "DD/MM/YYYY, HH:mm:ss"
                            }}
                            variant="standard"
                          />
                        </Grid>
                      )}
                      {banner.banner_type === "image" && (
                        <Grid item xs={12}>
                          <div
                            style={{
                              color: "rgba(0, 0, 0, 0.54)",
                              fontSize: "0.8rem",
                              marginBottom: 0
                            }}
                          >
                            <TranslatableParagraph translationLabel="resources.pageContents.fields.title_popup" />
                          </div>
                        </Grid>
                      )}

                      {banner.banner_type === "image" && (
                        <Grid item xs={12}>
                          <div>
                            <RichTextInput
                              toolbar={[BannerToolbarOptions]}
                              source={"bannersCarousel[" + index + "].banner_title"}
                              label={false}
                              variant="standard"
                            />
                          </div>
                        </Grid>
                      )}

                      {banner.banner_type === "image" && (
                        <Grid item xs={12}>
                          <div>
                            <TextInput
                              multiline
                              fullWidth
                              source={"bannersCarousel[" + index + "].banner_link"}
                              label="resources.pageContents.fields.link"
                              variant="standard"
                              validate={ValidateBannerLink}
                            />
                          </div>
                        </Grid>
                      )}
                      {banner.banner_type === "image" && (
                        <Grid item xs={12} md={4}>
                          <div>
                            <ImageInput
                              ratio="2:1"
                              maxSize={20000000}
                              imageSize={["800:400"]}
                              source={"bannersCarousel[" + index + "].imageRawMobile"}
                              label="resources.pageContents.fields.image_mobile"
                              labelSingle="ra.input.image.upload_single"
                              accept=".jpg,.jpeg,.png"
                            >
                              <CustomImageField source="src" />
                            </ImageInput>
                          </div>
                        </Grid>
                      )}

                      {banner.banner_type === "image" && (
                        <Grid item xs={12} md={4}>
                          <div>
                            <ImageInput
                              ratio="4:1"
                              maxSize={2000000}
                              imageSize={["1920:480"]}
                              source={"bannersCarousel[" + index + "].imageRawDesktop"}
                              label="resources.pageContents.fields.image_desktop"
                              labelSingle="ra.input.image.upload_single"
                              accept=".jpg,.jpeg,.png"
                            >
                              <CustomImageField source="src" />
                            </ImageInput>
                          </div>
                        </Grid>
                      )}

                      {banner.banner_type === "image" && (
                        <Grid item xs={12} md={4}>
                          <div>
                            <ImageInput
                              ratio="5:1"
                              maxSize={20000000}
                              imageSize={["2560:512", "3840x768"]}
                              source={"bannersCarousel[" + index + "].imageRaw4K"}
                              label="resources.pageContents.fields.image_4k"
                              labelSingle="ra.input.image.upload_single"
                              accept=".jpg,.jpeg,.png"
                            >
                              <CustomImageField source="src" />
                            </ImageInput>
                          </div>
                        </Grid>
                      )}

                      {banner.banner_type === "video" && (
                        <Grid item xs={12} md={12}>
                          <div>
                            <FileInput
                              label="resources.pageContents.fields.video"
                              labelSingle="resources.pageContents.fields.video"
                              source={"bannersCarousel[" + index + "].videoRaw"}
                            >
                              <CustomVideoField source="src" title="title" />
                            </FileInput>
                          </div>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end"
                          }}
                        >
                          <ButtonToggle
                            disabled={addNewItem && !banner.banner_type}
                            source={"bannersCarousel[" + index + "].show"}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Dialog>
              );
            }}
          </CustomFormIterator>
        </ArrayInput>
      </Fragment>
    );
  }
}
