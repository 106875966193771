/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useMemo } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  DateInput,
  DateTimeInput,
  BooleanInput,
  NumberInput,
  FormDataConsumer,
  required,
  Toolbar,
  SaveButton,
  minLength,
  maxLength
} from "react-admin";
import { useForm, useFormState } from "react-final-form";
import debounce from "../../utils/functions/debounce";
import { handleSingleRequest } from "../../services/api/restClient";
import { GET_LIST, GET_MANY_REFERENCE } from "../../services/api/types";

// Icon for field User levels and Become member if not appropritate roles are selected.
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { withStyles } from "@material-ui/core/styles";
// Local Components
import AutocompleteArrayInput from "../../components/CustomAutocompleteArrayInput";
import {
  priceLevels,
  orderTypes,
  ruleTypes,
  paymentOptions,
  comparatorOperators
} from "./ParameterConstants";
import DeleteConfirmation from "../../components/DeleteConfirmation";
// Filters & Styles
import { externalNumberFilter, nameFilter, emailFilter, cuNumberFilter } from "../../utils/filters";
import { dateValidator, maxValue, minValue } from "../../utils/validators";
import {
  userTypesAndRolesValidation,
  productQuantityOperatorValidation,
  productAmountOperatorValidation,
  productQuantityValueValidation,
  productAmountValueValidation
} from "./validators";
import { FormSeperator } from "./FormSeperator";
import { styles } from "../styles";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";

const stylesRules = {
  selectSmallLabel: {
    "& > label": {
      fontSize: 10
    }
  }
};

let currentPromoCode = "";
let usedPromoCodes = [];
let currentRuleName = "";
let usedRuleNames = [];
const couponValidator = value =>
  value && /^([a-zA-Z0-9]{1,20})$/.test(value)
    ? usedPromoCodes.includes(value)
      ? `Promo code is in use`
      : undefined
    : `Promo code is not valid`;

const ruleNameValidator = value =>
  value && value.trim()
    ? usedRuleNames.includes(value)
      ? `Rule name is in use`
      : undefined
    : `Rule name is not valid`;

const Title = ({ record }) => (
  <TranslatableParagraph
    customStyling={{ display: "inline-block", margin: 0 }}
    translationLabel="resources.priceRules.customLabels.editTitle"
    customLabel={record.rule.name}
  />
);

const ConfirmationToolbar = props => (
  <Toolbar {...props} style={styles.toolbar}>
    <SaveButton />
    <DeleteConfirmation undoable={false} />
  </Toolbar>
);

const SelectRuleType = ({
  formData,
  autoStop,
  setCouponTypeState,
  setSelectedCouponState,
  ...rest
}) => {
  const form = useForm();

  useEffect(() => {
    if (formData.rule.coupon_code && !autoStop) {
      setCouponTypeState();
      form.change("rule.rule_type", ruleTypes[2].id);
    }
  });

  return (
    <SelectInput
      source="rule.rule_type"
      choices={ruleTypes}
      validate={required()}
      onChange={setSelectedCouponState}
      {...rest}
    />
  );
};

const SpecificUsersPromotion = ({ formData }) => {
  const form = useForm();

  return (
    <BooleanInput
      label="resources.priceRules.fields.isSpecificUserPromotion"
      source="isSpecificUserPromotion"
      defaultValue={formData.rule.user_ids && formData.rule.user_ids.length > 0}
      onChange={() => {
        if (!formData.isSpecificUserPromotion) {
          form.change("rule.user_type_ids", []);
          form.change("rule.user_role_ids", []);
          form.change("rule.user_level_ids", []);
        } else {
          form.change("rule.user_ids", []);
        }
      }}
    />
  );
};

const ProductQuantityAndAmountOptions = ({ formData }) => {
  const form = useForm();
  console.log(formData);

  useEffect(() => {
    if (!formData.rule.product_amount.operator || formData.rule.product_amount.operator === null) {
      form.change("rule.product_amount", {
        operator: "",
        value: formData.rule.product_amount.value
      });
    }
    if (
      !formData.rule.product_quantity.operator ||
      formData.rule.product_quantity.operator === null
    ) {
      form.change("rule.product_quantity", {
        operator: "",
        value: formData.rule.product_quantity.value
      });
    }
  }, [formData.rule.product_amount.operator, formData.rule.product_quantity.operator]);

  return (
    <div>
      {!formData.rule.is_bonus ? (
        <>
          <SelectInput
            label="resources.priceRules.fields.rule.product_quantity.operator"
            source="rule.product_quantity.operator"
            validate={productQuantityOperatorValidation}
            choices={comparatorOperators}
            onChange={() => {
              formData.rule.product_quantity &&
              formData.rule.product_quantity.value < 2 &&
              formData.rule.product_quantity.operator === "<"
                ? form.change("rule.combine_products", false)
                : form.change("rule.combine_products", false);
            }}
            style={{ width: "256px" }}
            variant="standard"
            allowEmpty
          />
          <NumberInput
            label="resources.priceRules.fields.rule.product_quantity.value"
            source="rule.product_quantity.value"
            validate={productQuantityValueValidation}
            onChange={() => {
              formData.rule.product_quantity &&
              formData.rule.product_quantity.value < 2 &&
              formData.rule.product_quantity.operator === "<"
                ? form.change("rule.combine_products", false)
                : form.change("rule.combine_products", false);
            }}
            style={{ width: "256px" }}
            variant="standard"
            min="0"
            allowEmpty
          />

          <SelectInput
            label="resources.priceRules.fields.rule.product_amount.operator"
            source="rule.product_amount.operator"
            validate={productAmountOperatorValidation}
            onChange={() => {
              formData.rule.product_amount &&
              formData.rule.product_amount.value < 2 &&
              formData.rule.product_amount.operator === "<"
                ? form.change("rule.combine_products", false)
                : form.change("rule.combine_products", false);
            }}
            choices={comparatorOperators}
            style={{ width: "256px" }}
            variant="standard"
            allowEmpty
          />
          <NumberInput
            label="resources.priceRules.fields.rule.product_amount.value"
            source="rule.product_amount.value"
            validate={productAmountValueValidation}
            onChange={() => {
              formData.rule.product_amount &&
              formData.rule.product_amount.value < 2 &&
              formData.rule.product_amount.operator === "<"
                ? form.change("rule.combine_products", false)
                : form.change("rule.combine_products", false);
            }}
            style={{ width: "256px" }}
            variant="standard"
            min="0"
            allowEmpty
          />
        </>
      ) : (
        <div style={styles.rolesContainer}>
          <SupervisorAccountIcon style={{ verticalAlign: `middle` }} />{" "}
          <p style={{ fontSize: "14px", marginTop: 0, padding: 5 }}>
            To access <strong>Product Quantities</strong> and <strong>Amount Values</strong> please
            disable "Is bonus?" switch above.
          </p>
        </div>
      )}
    </div>
  );
};

const FilterUsersBy = () => {
  const { values: formData } = useFormState();

  const [filterBy, setFilterBy] = useState("email");

  const emailFilterRenderer = props => {
    return `${props.email} ${props.external_id ? `(${props.external_id})` : ""}`;
  };

  let activeFilterElements = document.getElementsByClassName("userFilterButton");

  return (
    <>
      <div
        className="filterGroup"
        style={{
          display: "flex",
          justifyContent: "between",
          alignItems: "center",
          flex: "1 1 100%",
          gap: "10px",
          width: "100%",
          minWidth: "256px",
          opacity: formData.isSpecificUserPromotion ? "1" : "0.6",
          pointerEvents: formData.isSpecificUserPromotion ? "auto" : "none"
        }}
      >
        <button
          className="userFilterButton activeFilter"
          style={{
            border: "none",
            padding: "10px",
            borderRadius: "15px",
            boxShadow: "5px 5px 10px rgba(19, 22, 22, .15)",
            cursor: "pointer"
          }}
          onClick={e => {
            e.preventDefault();
            e.target.classList.add("activeFilter");
            activeFilterElements[1]?.classList.remove("activeFilter");
            setFilterBy("email");
          }}
        >
          <TranslatableParagraph
            textOnly
            translationLabel="resources.priceRules.customLabels.filterByEmail"
          />
        </button>
        <button
          className="userFilterButton"
          style={{
            border: "none",
            padding: "10px",
            borderRadius: "15px",
            boxShadow: "5px 5px 10px rgba(19, 22, 22, .15)",
            cursor: "pointer"
          }}
          onClick={e => {
            e.preventDefault();
            e.target.classList.add("activeFilter");
            activeFilterElements[0]?.classList.remove("activeFilter");
            setFilterBy("cuNumber");
          }}
        >
          <TranslatableParagraph
            textOnly
            translationLabel="resources.priceRules.customLabels.filterByCU"
          />
        </button>
      </div>
      <ReferenceArrayInput
        label="resources.priceRules.fields.rule.user_ids"
        source="rule.user_ids"
        reference="users"
        filterToQuery={filterBy === "email" ? emailFilter : cuNumberFilter}
        allowEmpty={true}
      >
        <AutocompleteArrayInput
          disabled={!formData.isSpecificUserPromotion}
          optionText={emailFilterRenderer}
        />
      </ReferenceArrayInput>
    </>
  );
};

const SearcherAndPromoCodeProductsOptions = ({
  formData,
  triggerHelper,
  setProductDefinitionTriggerHelper,
  setTriggerHelperTrue,
  productDefinition,
  selectedCoupon
}) => {
  const [rulePriceProducts, setRulePriceProducts] = useState([]);
  const [suggestedProducts, setSuggestedProducts] = useState([]);
  const form = useForm();
  useEffect(() => {
    if (formData.rule && formData.rule.rule_type === "promo_code" && triggerHelper) {
      const productDefinitionTriggerHelperValue = {
        productDefinition:
          (formData.rule && formData.rule.category_ids && formData.rule.category_ids.length > 0) ||
          (formData.rule && formData.rule.product_ids && formData.rule.product_ids.length > 0)
            ? false
            : true,
        triggerHelper: false
      };

      setProductDefinitionTriggerHelper(productDefinitionTriggerHelperValue);
    }
    if (!formData?.rule?.category_ids?.length > 0) {
      form.change("rule.include_subcategories", false);
    } else if (formData?.rule?.category_ids?.length > 0 && formData?.rule?.promoted_price) {
      form.change("rule.include_subcategories", true);
    }
  });

  const refetchProducts = debounce(searchString => {
    handleSingleRequest(GET_MANY_REFERENCE, "products", {
      sort: { field: "name", order: "ASC" },
      filter: { external_number_ilike: searchString }
    })
      .then(({ data = [] }) => {
        setSuggestedProducts(data);
      })
      .catch(error => console.log(error));
  }, 300);

  const handleInputChange = searchString => {
    refetchProducts(searchString);
  };

  useEffect(() => {
    // initially fetch products which starts with A
    handleInputChange("A");
    // All products from the current price rule
    handleSingleRequest(GET_MANY_REFERENCE, "products", {
      sort: { field: "name", order: "ASCC" },
      filter: {},
      pagination: { page: "all" },
      price_rule_id: formData.id
    })
      .then(({ data = [] }) => {
        setRulePriceProducts(data);
        form.change(
          "rule.product_ids",
          data.map(pr => pr.id)
        );
      })
      .catch(error => console.log(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = value => {
    const { newValue, method } = value;

    switch (method) {
      case "enter":
      case "click":
        setRulePriceProducts(prev => [
          ...prev,
          suggestedProducts.find(sp => sp.external_number === newValue)
        ]);
        break;
      case "type":
        handleInputChange(newValue);
        break;
      default:
        break;
    }
  };

  const filteredProducts = useMemo(() => {
    return suggestedProducts.filter(sp => !rulePriceProducts.map(rp => rp.id).includes(sp.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestedProducts]);

  return (
    <>
      <div style={styles.productsCategories.wrapper}>
        <TranslatableParagraph
          customStyling={{ fontSize: "14px" }}
          translationLabel="resources.priceRules.customLabels.label"
        />
        <ReferenceArrayInput
          label="resources.priceRules.fields.rule.category_ids"
          source="rule.category_ids"
          reference="productCategories"
          sort={{ field: "name", order: "ASC" }}
          filterToQuery={nameFilter}
          onChange={setTriggerHelperTrue}
          allowEmpty
        >
          <AutocompleteArrayInput style={styles.productsCategories.inputField} optionText="name" />
        </ReferenceArrayInput>
        <BooleanInput
          source="rule.include_subcategories"
          label="resources.priceRules.fields.rule.include_subcategories"
        />
        {!formData?.rule?.category_ids?.length > 0 && (
          <p style={{ fontSize: "10px", color: "#bbb" }}>*Add category to include subcategories</p>
        )}
        <AutocompleteArrayInput
          source="rule.product_ids"
          label="resources.priceRules.fields.rule.product_ids"
          choices={[...rulePriceProducts, ...suggestedProducts]}
          openValue="id"
          optionText="external_number"
          allowEmpty
          style={{ width: "256px" }}
          variant="standard"
          customOnChange={handleOnChange}
          customSuggestions={filteredProducts}
          limitChoicesToValue
        />

        {productDefinition && (
          <p style={{ color: "#f44336", fontSize: "0.65rem" }}>
            Please make product definition by adding product or select product category
          </p>
        )}
      </div>
      {!selectedCoupon && (
        <div style={styles.productsCategories.wrapper}>
          <BooleanInput
            label="resources.priceRules.fields.rule.is_bonus"
            source="rule.is_bonus"
            onChange={bonusValue => {
              if (bonusValue) {
                form.change("rule.product_quantity.operator", "");
                form.change("rule.product_quantity.value", null);
                form.change("rule.product_amount.operator", "");
                form.change("rule.product_amount.value", null);
              }
            }}
          />

          <ProductQuantityAndAmountOptions formData={formData} />

          <NumberInput
            label="resources.priceRules.fields.rule.xx_percentage"
            source="rule.xx_percentage"
            inputProps={{
              step: 1
            }}
            validate={[minValue(1), maxValue(100)]}
            style={{ width: "256px" }}
            variant="standard"
            min="0"
          />

          <BooleanInput
            source="rule.combine_products"
            label="resources.priceRules.fields.rule.combine_products"
            options={{ disabled: false }}
          />
        </div>
      )}
    </>
  );
};

const SelectedCouponDatesAndCartAmount = ({ formData, allowDateChange, setAllowDateChange }) => {
  const form = useForm();

  useEffect(() => {
    if (allowDateChange) {
      const startDate = formData.rule.start_date + "T" + formData.rule.start_time + ":00";
      const endDate = formData.rule.end_date + "T" + formData.rule.end_time + ":00";

      setAllowDateChange();
      form.change("rule.start_date", startDate);
      form.change("rule.end_date", endDate);
    }
  });

  return (
    <div style={styles.filedsWrapper}>
      <DateTimeInput
        label="resources.priceRules.fields.rule.start_date"
        source="rule.start_date"
        validate={[required(), dateValidator("YYYY-MM-DDTHH:mm:ss.sssZ")]}
        variant="standard"
      />
      <DateTimeInput
        label="resources.priceRules.fields.rule.end_date"
        source="rule.end_date"
        validate={[required(), dateValidator("YYYY-MM-DDTHH:mm:ss.sssZ")]}
        variant="standard"
      />
      <NumberInput
        label="resources.priceRules.fields.rule.min_cart_amount"
        source="rule.min_cart_amount"
        validate={required()}
        variant="standard"
        min="0"
      />
    </div>
  );
};

const UserLevelField = () => {
  const form = useForm();
  const { values: formData } = useFormState();

  const shouldDisplayUserLevelField =
    formData.rule &&
    formData.rule.user_role_ids &&
    formData.rule.user_role_ids.includes("40e505de-2451-11e8-b34c-37a6db76516b") &&
    formData.rule.user_role_ids.length === 1;

  const shouldDisplayAffiliateMixMessage =
    formData.rule &&
    formData.rule.user_role_ids &&
    formData.rule.user_role_ids.length > 0 &&
    ((formData.rule.user_role_ids.includes("40e505de-2451-11e8-b34c-37a6db76516b") &&
      formData.rule.user_role_ids.includes("40c210ba-2451-11e8-b34a-ff279e1b43e6")) ||
      (formData.rule.user_role_ids.includes("40e505de-2451-11e8-b34c-37a6db76516b") &&
        formData.rule.user_role_ids.includes("40b098a8-2451-11e8-b349-6b57a44afd96")) ||
      (formData.rule.user_role_ids.includes("40d3ec7c-2451-11e8-b34b-df8ee6486630") &&
        formData.rule.user_role_ids.includes("40c210ba-2451-11e8-b34a-ff279e1b43e6")) ||
      (formData.rule.user_role_ids.includes("40d3ec7c-2451-11e8-b34b-df8ee6486630") &&
        formData.rule.user_role_ids.includes("40b098a8-2451-11e8-b349-6b57a44afd96")));

  useEffect(() => {
    if (shouldDisplayUserLevelField) {
      return;
    } else if (shouldDisplayAffiliateMixMessage) {
      form.change("rule.user_level_ids", []);
      form.change("rule.user_role_ids", []);
    } else {
      formData.rule && form.change("rule.user_level_ids", []);
    }
  }, [shouldDisplayUserLevelField, shouldDisplayAffiliateMixMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  return shouldDisplayUserLevelField ? (
    <ReferenceArrayInput
      label="resources.priceRules.fields.rule.user_level_ids"
      source="rule.user_level_ids"
      reference="userLevels"
      sort={{ field: "name", order: "ASC" }}
      filterToQuery={userLevelValue => {
        if (userLevelValue) {
          return { name_ilike: `${userLevelValue}%`, name_ne: "Club Member" };
        } else {
          return { name_ne: "Club Member" };
        }
      }}
      allowEmpty
    >
      <SelectArrayInput
        optionText="name"
        disabled={formData.isSpecificUserPromotion}
        style={{ width: "256px" }}
        variant="standard"
      />
    </ReferenceArrayInput>
  ) : shouldDisplayAffiliateMixMessage ? (
    <div style={styles.rolesContainer}>
      <SupervisorAccountIcon style={{ verticalAlign: `middle` }} />{" "}
      <TranslatableParagraph
        customStyling={{
          fontSize: "14px",
          marginTop: "0px",
          padding: "5px"
        }}
        translationLabel="resources.priceRules.customLabels.affiliate_mix"
      />
    </div>
  ) : (
    <div style={styles.rolesContainer}>
      <SupervisorAccountIcon style={{ verticalAlign: `middle` }} />{" "}
      <TranslatableParagraph
        customStyling={{
          fontSize: "14px",
          marginTop: "0px",
          padding: "5px"
        }}
        translationLabel="resources.priceRules.customLabels.affiliate_levels"
      />
    </div>
  );
};

const BecomeMemberField = () => {
  const form = useForm();
  const { values: formData } = useFormState();

  const shouldDisplayBecomeMemberLabel =
    formData.rule &&
    (formData.rule.user_role_ids.includes("40e505de-2451-11e8-b34c-37a6db76516b") ||
      formData.rule.user_role_ids.includes("40d3ec7c-2451-11e8-b34b-df8ee6486630") ||
      !formData.rule.user_role_ids.length);

  useEffect(() => {
    if (shouldDisplayBecomeMemberLabel) {
      form.change("rule.become_member", false);
    }
  }, [shouldDisplayBecomeMemberLabel]); // eslint-disable-line react-hooks/exhaustive-deps

  return shouldDisplayBecomeMemberLabel ? (
    <div style={styles.rolesContainer}>
      <SupervisorAccountIcon style={{ verticalAlign: `middle` }} />{" "}
      <TranslatableParagraph
        customStyling={{
          fontSize: "14px",
          marginTop: "0px",
          padding: "5px"
        }}
        translationLabel="resources.priceRules.customLabels.become_member_label"
      />
    </div>
  ) : (
    <BooleanInput
      label="resources.priceRules.fields.rule.become_member"
      source="rule.become_member"
    />
  );
};

class PriceRulesEdit extends React.Component {
  state = {
    selectedCoupon: false,
    autoStop: false,
    allowDateChange: false,
    productDefinition: false,
    triggerHelper: true,
    usedPromoCodesFetched: false,
    usedRuleNamesFetched: false
  };

  componentDidMount() {
    handleSingleRequest(GET_LIST, "priceRules", {
      pagination: {
        page: "all"
      },
      sort: { field: "created_at", order: "DESC" },
      filter: {}
    })
      .then(({ data }) => {
        if (data.length !== 0) {
          const fetchedPromoCodes = [];
          const fetchedRuleNames = [];
          data.forEach(record => {
            if (record.rule.coupon_code) {
              fetchedPromoCodes.push(record.rule.coupon_code);
            }
            if (record.rule.name) {
              fetchedRuleNames.push(record.rule.name) === record.rule.name
                ? fetchedRuleNames.push(record.rule.name)
                : null;
            }
          });
          usedPromoCodes = fetchedPromoCodes.filter(coupon => coupon !== currentPromoCode);
          usedRuleNames = fetchedRuleNames.filter(rule => rule !== currentRuleName);
          this.setState({ usedPromoCodesFetched: true });
          this.setState({ usedRuleNamesFetched: true });
        }
      })
      .catch(error => console.log(error));
  }

  componentWillUnmount() {
    currentPromoCode = "";
    usedPromoCodes = [];
    currentRuleName = "";
    usedRuleNames = [];
  }

  setCouponTypeState = () => {
    this.setState({
      autoStop: true,
      selectedCoupon: true,
      allowDateChange: true
    });
  };

  setSelectedCouponState = event => {
    this.setState({ selectedCoupon: event.target.value === "promo_code" });
    if (event.target.value === "promo_code") {
      this.setState({ autoStop: true });
    }
  };

  setAllowDateChange = () => {
    this.setState({ allowDateChange: false });
  };

  setProductDefinitionTriggerHelper = productDefinitionTriggerHelperValue => {
    this.setState({ ...productDefinitionTriggerHelperValue });
  };

  setTriggerHelperTrue = () => {
    this.setState({ triggerHelper: true });
  };

  render() {
    const { classes, ...restProps } = this.props;

    return (
      <Edit title={<Title />} {...restProps}>
        <SimpleForm variant="standard" toolbar={<ConfirmationToolbar />}>
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.rule.name) {
                currentRuleName = formData.rule.name;
              }
              return (
                <TextInput
                  style={{ minWidth: "256px" }}
                  source="rule.name"
                  label="resources.priceRules.fields.rule.name"
                  validate={[required(), ruleNameValidator]}
                  variant="standard"
                />
              );
            }}
          </FormDataConsumer>

          <FormDataConsumer>
            {formDataProps => (
              <SelectRuleType
                autoStop={this.state.autoStop}
                setCouponTypeState={this.setCouponTypeState}
                setSelectedCouponState={this.setSelectedCouponState}
                {...formDataProps}
              />
            )}
          </FormDataConsumer>
          <SelectArrayInput
            label="resources.priceRules.fields.rule.order_type"
            source="rule.order_type"
            choices={orderTypes}
            validate={required()}
          />
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.rule.coupon_code) {
                currentPromoCode = formData.rule.coupon_code;
              }
              if (this.state.selectedCoupon) {
                return (
                  <TextInput
                    label={
                      <TranslatableParagraph
                        textOnly
                        translationLabel="resources.priceRules.fields.rule.coupon_code"
                      />
                    }
                    source="rule.coupon_code"
                    validate={couponValidator}
                    style={{ width: "256px" }}
                    variant="standard"
                  />
                );
              }
            }}
          </FormDataConsumer>
          {!this.state.selectedCoupon && (
            <SelectArrayInput
              source="rule.payment_options"
              choices={paymentOptions}
              validate={required()}
            />
          )}
          {this.state.selectedCoupon && (
            <>
              <p
                style={{
                  marginTop: "15px",
                  color: "#0000008a",
                  padding: 0,
                  fontSize: "0.65rem"
                }}
              >
                Payment options
              </p>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {paymentOptions.map(item => {
                  return (
                    <p
                      key={item.name}
                      style={{
                        marginRight: "10px",
                        backgroundColor: "#ddd",
                        color: "#000",
                        padding: "7px 11px",
                        borderRadius: "20px",
                        fontSize: "0.65rem"
                      }}
                    >
                      {item.name}
                    </p>
                  );
                })}
              </div>
            </>
          )}
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <div style={styles.productsCategories.wrapper}>
                  <SpecificUsersPromotion formData={formData} />
                  <FilterUsersBy />
                  <ReferenceArrayInput
                    label="resources.priceRules.fields.rule.user_type_ids"
                    source="rule.user_type_ids"
                    reference="userTypes"
                    sort={{ field: "name", order: "ASC" }}
                    filterToQuery={nameFilter}
                    validate={userTypesAndRolesValidation}
                  >
                    <SelectArrayInput
                      optionText="name"
                      disabled={formData.isSpecificUserPromotion}
                      style={{ width: "256px" }}
                      variant="standard"
                    />
                  </ReferenceArrayInput>

                  <ReferenceArrayInput
                    label="resources.priceRules.fields.rule.user_role_ids"
                    source="rule.user_role_ids"
                    reference="roles"
                    sort={{ field: "name", order: "ASC" }}
                    filterToQuery={nameFilter}
                    validate={userTypesAndRolesValidation}
                  >
                    <SelectArrayInput
                      optionText="name"
                      disabled={formData.isSpecificUserPromotion}
                      style={{ width: "256px" }}
                      variant="standard"
                    />
                  </ReferenceArrayInput>

                  <UserLevelField />
                </div>
              );
            }}
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData }) => {
              if (
                (formData.rule && formData.rule.rule_type === "searcher") ||
                (formData.rule && formData.rule.rule_type === "promo_code")
              ) {
                return (
                  <SearcherAndPromoCodeProductsOptions
                    formData={formData}
                    triggerHelper={this.state.triggerHelper}
                    setProductDefinitionTriggerHelper={this.setProductDefinitionTriggerHelper}
                    setTriggerHelperTrue={this.setTriggerHelperTrue}
                    productDefinition={this.state.productDefinition}
                    selectedCoupon={this.state.selectedCoupon}
                  />
                );
              } else {
                return (
                  <div style={styles.productsCategories.quantityOperator}>
                    <SelectInput
                      label="resources.priceRules.fields.rule.cart_amount.operator"
                      source="rule.cart_amount.operator"
                      choices={comparatorOperators}
                      variant="standard"
                      allowEmpty
                    />
                    <NumberInput
                      label="resources.priceRules.fields.rule.cart_amount.value"
                      source="rule.cart_amount.value"
                      variant="standard"
                      min="0"
                    />
                  </div>
                );
              }
            }}
          </FormDataConsumer>

          <BecomeMemberField />

          <FormDataConsumer>
            {({ formData }) => {
              if (
                (formData.rule && formData.rule.rule_type === "searcher") ||
                (formData.rule && formData.rule.rule_type === "cart_amount")
              ) {
                return (
                  <div style={styles.filedsWrapper}>
                    <ReferenceArrayInput
                      label="resources.priceRules.fields.rule.parent_rules"
                      source="rule.parent_rules"
                      reference="priceRules"
                      filter={{
                        "rule.parent_rules_is": null,
                        id_ne: this.props.id
                      }}
                      perPage={10000}
                      sort={{ field: "created_at", order: "DESC" }}
                    >
                      <SelectArrayInput optionText="rule.name" variant="standard" />
                    </ReferenceArrayInput>

                    <NumberInput
                      label="resources.priceRules.fields.rule.club_membership_age.value"
                      source="rule.club_membership_age.value"
                      variant="standard"
                      min="0"
                      validate={minValue(0)}
                      allowEmpty
                    />

                    <SelectInput
                      label="resources.priceRules.fields.membership_since"
                      source="rule.club_membership_age.operator"
                      className={classes.selectSmallLabel}
                      choices={comparatorOperators}
                      variant="standard"
                      allowEmpty
                    />
                  </div>
                );
              }
            }}
          </FormDataConsumer>

          <FormSeperator />

          <SelectInput source="rule.dl_level" choices={priceLevels} validate={required()} />

          <TextInput source="rule.message" multiline validate={[minLength(20), maxLength(100)]} />

          {!this.state.selectedCoupon && (
            <>
              <div style={styles.filedsWrapper}>
                <DateInput
                  label="resources.priceRules.fields.rule.start_date"
                  source="rule.start_date"
                  validate={[required(), dateValidator("YYYY-MM-DD")]}
                  variant="standard"
                />
                <DateInput
                  label="resources.priceRules.fields.rule.end_date"
                  source="rule.end_date"
                  validate={[required(), dateValidator("YYYY-MM-DD")]}
                  variant="standard"
                />
              </div>
            </>
          )}

          <FormDataConsumer>
            {({ formData }) => {
              if (formData.rule && this.state.selectedCoupon) {
                return (
                  <SelectedCouponDatesAndCartAmount
                    formData={formData}
                    allowDateChange={this.state.allowDateChange}
                    setAllowDateChange={this.setAllowDateChange}
                  />
                );
              }
            }}
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData }) => {
              if (formData.rule && formData.rule.rule_type === "searcher") {
                return (
                  <BooleanInput
                    label="resources.priceRules.fields.rule.promoted_price"
                    source="rule.promoted_price"
                  />
                );
              }
            }}
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData }) => {
              const validateMinInstallment = (value, formData) => {
                const minInstallments = value || 0;
                const maxInstallments = formData?.rule?.max_installments || 0;
                return minInstallments > maxInstallments && maxInstallments > 0
                  ? "resources.priceRules.customLabels.numberOfInstallments"
                  : !minInstallments || !maxInstallments || minInstallments <= maxInstallments
                  ? null
                  : "resources.priceRules.customLabels.numberOfInstallments";
              };
              const validateMaxInstallment = (value, formData) => {
                const maxInstallments = value || 0;
                const minInstallments = formData?.rule?.min_installments || 0;
                return minInstallments > maxInstallments && maxInstallments > 0
                  ? "resources.priceRules.customLabels.numberOfInstallments"
                  : !maxInstallments || !minInstallments || maxInstallments >= minInstallments
                  ? null
                  : "resources.priceRules.customLabels.numberOfInstallments";
              };
              if (
                (formData.rule && formData.rule.rule_type === "searcher") ||
                (formData.rule && formData.rule.rule_type === "cart_amount")
              ) {
                return (
                  <div style={styles.filedsWrapper}>
                    <NumberInput
                      label="resources.priceRules.fields.rule.min_installments"
                      source="rule.min_installments"
                      variant="standard"
                      min="0"
                      allowEmpty
                      validate={validateMinInstallment}
                    />
                    <NumberInput
                      label="resources.priceRules.fields.rule.max_installments"
                      source="rule.max_installments"
                      variant="standard"
                      min="0"
                      allowEmpty
                      validate={validateMaxInstallment}
                    />
                  </div>
                );
              }
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    );
  }
}

export default withStyles(stylesRules)(PriceRulesEdit);
