import React, { Fragment, useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button } from "@material-ui/core";

import Checkbox from "@material-ui/core/Checkbox";

import { handleSingleRequest } from "../services/api/restClient";

import { utils, writeFileXLSX } from "xlsx";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "7.5px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "80%",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    overflowY: "scroll",
    height: "400px"
  }
}));

const lang = localStorage.getItem("lang");

export default function ExportWithOptions(props) {
  const classes = useStyles();
  const [productList, setProductList] = useState([]);
  const [filteredProductList, setFilteredProductList] = useState([]);

  const [columns, setColumns] = useState([]);
  const [checkedColumns, setCheckedColumns] = useState([]);

  const [showTable, setShowTable] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const description = name => {
    switch (name) {
      case "is_visible_in_shop":
        return "Product visible in shop";
      case "has_content":
        return "Product has content";
      case "external_number":
        return "Product's external number";
      case "additional_file_path":
        return "Product has additional file path";
      case "brand":
        return "Product have brand";
      case "category_id":
        return "Product have category_id";
      case "created_at":
        return "Product's created at date";
      case "deleted_at":
        return "Product's deleted at date";
      case "description":
        return "Product's description";
      case "external_id":
        return "Product's external id";
      case "feed_description":
        return "Products have feed description info";
      case "feed_name":
        return "Product have feed name info";
      case "file_path":
        return "product has file path";
      case "head_tag_id":
        return "Product has head tag id";
      case "id":
        return "Product's id";
      case "inventory":
        return "Products inventory";
      case "name":
        return "Product's name";
      case "presentation":
        return "Product' presentation";
      case "product_inventory":
        return "Product have inventory";
      case "product_type_id":
        return "Product's type id";
      case "rid":
        return "Products rid";
      case "short_description":
        return "Product's short description (HTML)";
      case "show_discount":
        return "Product show discount";
      case "sirv_url":
        return "Product's sirv url";
      case "stock_number":
        return "Product's stock number";
      case "story":
        return "Product's story (HTML)";
      case "unit_type_id":
        return "Product's unit type id";
      case "updated_at":
        return "Product's updated at date";
      case "url_slug":
        return "Product's url slug";
      default:
        return "";
    }
  };

  // * Handle checkbox toggles (data not assigned)
  const handleCheckBoxToggle = event => {
    const { name } = event.target;

    if (checkedColumns.includes(name)) {
      let filteredColumns = checkedColumns.filter(item => item !== name);
      setCheckedColumns(filteredColumns);
    } else {
      setCheckedColumns([...checkedColumns, name]);
    }

    return checkedColumns.includes(name);
  };

  //    * Reset filters and hide table with products
  const resetFilters = () => {
    const originalData = [...productList];

    setShowTable(false);
    setFilteredProductList(originalData);
    setCheckedColumns([]);
  };

  //   * Filter products per checkbox values (data assigned)
  const filterProducts = () => {
    let filteredData = productList.filter(product => {
      return checkedColumns.every(item => product[item]);
    });

    setFilteredProductList(filteredData);
    setShowTable(true);
  };

  //  * Grab product list
  useEffect(() => {
    handleSingleRequest("GET_LIST", "products", {
      pagination: {
        page: "1",
        perPage: "1000"
      },
      filter: {},
      sort: {}
    }).then(({ data }) => {
      setProductList(data);
      setFilteredProductList(data);

      let mappedValues = new Map(Object.entries(data[0]));

      let columnsValues = [];
      for (const [key, value] of mappedValues) {
        columnsValues.push({ name: key, description: description(key) });
      }

      setColumns(columnsValues);
    });
  }, [open]);

  //    * Define checked field
  const checked = name => {
    return checkedColumns.includes(name);
  };

  //    * Checkbox with a label
  const Field = props => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px"
        }}
      >
        <Checkbox
          color="default"
          size="small"
          name={props?.name}
          checked={props?.state}
          inputProps={{ "aria-label": "primary checkbox" }}
          onChange={handleCheckBoxToggle}
          label={props?.label}
        />
        <span style={{ fontSize: "12px" }}>{props?.content}</span>
      </div>
    );
  };

  //    * Product List table UI
  const ProductList = () => {
    return (
      showTable && (
        <table style={{ width: "100%", maxWidth: "968px", overflowX: "scroll", textAlign: "left" }}>
          <thead>
            {checkedColumns.map(column => {
              return column ? <th key={column}>{column}</th> : null;
            })}
          </thead>
          <tbody>
            {filteredProductList.map((row, indexRow) => (
              <tr key={indexRow}>
                {checkedColumns.map((column, columnIndex) =>
                  typeof row[column] === "object" ? (
                    <td key={columnIndex}>
                      {row[column] ? Object.keys(row[column])[0] && "true" : false}
                    </td>
                  ) : (
                    <td key={columnIndex}>{row[column] === true ? "true" : row[column]}</td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )
    );
  };

  const exportTables = () => {
    const date = new Date();

    const dateString = date.toISOString().substring(0, 10);

    const filteredProducts = filteredProductList.map(obj => {
      return checkedColumns.reduce((acc, curr) => {
        acc[curr] = obj[curr];
        return acc;
      }, {});
    });

    const jsonToSheet = utils.json_to_sheet(filteredProducts, { header: checkedColumns });
    const exportTables = utils.book_new();
    utils.book_append_sheet(exportTables, jsonToSheet, "Product Tables");
    writeFileXLSX(exportTables, `ProductsFeed_${dateString}.xlsx`);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Export Products
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "20px"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "stretch",
                  gap: "10px"
                }}
              >
                <Button onClick={() => filterProducts()} variant="contained" color="primary">
                  Filter per toggled values
                </Button>

                <Button onClick={() => resetFilters()} variant="contained" color="secondary">
                  Reset filters
                </Button>
                <Button onClick={() => exportTables()} variant="contained" color="secondary">
                  Export XLSX
                </Button>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                  gridTemplateRows: "repeat(5, 1fr)",
                  gap: "10px",
                  overflow: "auto",
                  alignItems: "start"
                }}
              >
                {columns.map(item => (
                  <Field
                    name={item.name}
                    state={checked(item.name)}
                    label={item.description}
                    content={item.description}
                  />
                ))}
              </div>
            </div>
            <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
              <ProductList />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
