import React from "react";
import {
  TextField,
  Edit,
  SimpleForm,
  AutocompleteArrayInput,
  ReferenceArrayInput
} from "react-admin";
import { priceLevelNameTransform } from "./List";

const UserLevelTitle = ({ record }) => `EDIT USER DISCOUNT LEVELS`;

// Currently allowed price levels are D1,D2,D3,D4,D5,D6
// Until better solution changes will be made here
const allowedPriceLevels = ["D2", "D3", "D4", "D5", "D6"];
const priceLevelFilter = () => {
  return { name_in: allowedPriceLevels.join(",") };
};

const priceLevelNameFormat = record => {
  return priceLevelNameTransform(record.name);
};

export const UserLevelsEdit = props => {
  return (
    <Edit title={<UserLevelTitle />} {...props}>
      <SimpleForm>
        <TextField source="name" />
        <TextField source="key_name" />
        <TextField source="description" />
        <TextField source="upper_bound_currency" />
        <TextField source="upper_bound_value" />
        <ReferenceArrayInput
          label="Price levels"
          source="price_levels"
          reference="priceLevels"
          filterToQuery={priceLevelFilter}
          sort={{
            field: "name",
            order: "ASC"
          }}
          variant="standard"
        >
          <AutocompleteArrayInput
            optionText={priceLevelNameFormat}
            options={{
              suggestionsContainerProps: {
                modifiers: {
                  computeStyle: {
                    enabled: true,
                    gpuAcceleration: false
                  },
                  applyStyle: {
                    enabled: true
                  }
                }
              }
            }}
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};
