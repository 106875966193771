export const userTypesAndRolesValidation = (value, formValues) => {
  if (formValues.isSpecificUserPromotion) {
    return undefined;
  } else {
    if (value && value.length > 0) {
      return undefined;
    } else {
      return "Required";
    }
  }
};

export const productQuantityOperatorValidation = (value, formValues) => {
  if (
    formValues.rule.product_quantity &&
    (formValues.rule.product_quantity.value || formValues.rule.product_quantity.value === 0)
  ) {
    if (value) {
      return undefined;
    } else {
      return "Required";
    }
  } else {
    return undefined;
  }
};

export const productAmountOperatorValidation = (value, formValues) => {
  if (
    formValues.rule.product_amount &&
    (formValues.rule.product_amount.value || formValues.rule.product_amount.value === 0)
  ) {
    if (value) {
      return undefined;
    } else {
      return "Required";
    }
  } else {
    return undefined;
  }
};

export const productQuantityValueValidation = (value, formValues) => {
  if (
    formValues.rule.product_quantity &&
    formValues.rule.product_quantity.operator &&
    formValues.rule.product_quantity.operator.length !== ""
  ) {
    if (value || value === 0) {
      if (value < 0) {
        return "Can not be less than 0";
      } else {
        return undefined;
      }
    } else {
      return "Required";
    }
  } else {
    return undefined;
  }
};

export const productAmountValueValidation = (value, formValues) => {
  if (
    formValues.rule.product_amount &&
    formValues.rule.product_amount.operator &&
    formValues.rule.product_amount.operator.length !== ""
  ) {
    if (value || value === 0) {
      if (value < 0) {
        return "Can not be less than 0";
      } else {
        return undefined;
      }
    } else {
      return "Required";
    }
  } else {
    if (value || value === 0) {
      if (value < 0) {
        return "Can not be less than 0";
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }
};
