export function validationRegex(regex) {
  return function validate(value) {
    return regex.test(value);
  };
}

export const validateRepetetiveCharacters = validationRegex(/([a-zA-Z\s])\1{2,}/);
export const validateRepetetiveDashes = validationRegex(/^(?!.*--)[\w-]+$/);
export const validateDashLetters = validationRegex(/^[a-zA-Z0-9-]+$/);
export const validateLettersNumbersSpaces = validationRegex(/^[a-zA-Z0-9 ]+$/);
export const validateNumbers = validationRegex(/^[1-9]+$/);
export const disallowBrackets = validationRegex(/^<.*>$/);
export const validateTechDataValues = validationRegex(
  /^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~\s\u2122\u00AE\u0400-\u04FF\u00c0-\u00FF\u0100-\u017F\u0600–\u06FF\u0218-\u021B-\u060A-\u069A- ]*$/
);
